<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/light.jpg')"
      class="white--text"
      gradient="to bottom, rgba(11, 28, 61, .9), rgba(0,0,0,.4)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="700"
          width="100%"
        >
          <div class="align-center py-12">
            <v-img
              :src="require('@/assets/Logo Suilen-IT_190px.png')"
              :max-width="190"
            />
          </div>
          <base-body>
            Freelance Microsoft software development en SCRUM / Agile coaching.
          </base-body>

          <div class="d-flex align-center">
            <base-btn href="#expertises">
              Expertises
            </base-btn>

            <base-btn class="ml-6" href="#contact">
              Contact
            </base-btn>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
export default {
  name: "SectionHero",

  provide: {
    theme: { isDark: true }
  },

  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "50vh";

      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    }
  }
};
</script>
